<section *ngIf="mainService.storeData" class="py-5 overflow-hidden bg-primary" id="rest_info" style="background-image: url({{mainService.uploadPath+mainService.storeData.banner}});">
    <div class="container">
      <div class="d-flex align-items-center justify-content-center">
            <div class="rest_logo">
                <img class="img" *ngIf="mainService.storeData.logo" [src]="mainService.uploadPath+mainService.storeData.logo" alt="resturant logo" />
                <img class="img" *ngIf="!mainService.storeData.logo" src="assets/imgs/default-img.jpg" alt="default logo" />
            </div>
            <div class="rest_data">
                <h1 class="rest_title">{{mainService.storeData.name}}</h1>
                <h2 class="rest_description" *ngIf="mainService.storeData.prife"> {{mainService.storeData.prife}} </h2>
                <h2 class="rest_location">{{mainService.storeData.address}}</h2>
            </div>
            <div class="rest_social">
                <ng-container *ngIf="mainService.storeData.phones">
                    <ng-container *ngFor="let phoneData of mainService.storeData.phones">
                        <a href="tel:{{phoneData.phone}}"><i class='bx bxs-phone' ></i></a>
                        <a *ngIf="phoneData.is_whatsapp" href="https://api.whatsapp.com/send?phone=90{{phoneData.phone}}" target="_blank"><i class='bx bxl-whatsapp' ></i></a>
                    </ng-container>
                </ng-container>
                <a *ngIf="mainService.storeData.facebook" [href]="mainService.storeData.facebook" target="_blank"><i class='bx bxl-facebook-square'></i></a>
                <a *ngIf="mainService.storeData.instagram" [href]="mainService.storeData.instagram" target="_blank"><i class='bx bxl-instagram' ></i></a>
                <a *ngIf="mainService.storeData.youtube" [href]="mainService.storeData.youtube" target="_blank"><i class='bx bxl-youtube' ></i></a>
            </div>
        </div>
    </div>
  </section>
