<div class="text-center">

  <div class="form-group has-search  ">
    <input id="pac-input" type="text" class="form-control" placeholder="{{'L_location_on_map' |translate}}" [(ngModel)]="this.locationOnMapAddress">
    <span (click)="getCurrentLocation()">
      <img width="20" height="20" title="{{'L_goToYourLocation' |translate}}" src="assets/imgs/target.png">
    </span>
  </div>
  <div #gmap id="map" style="width:100%;height: calc(100vh - 120px);"></div>

  <div class="btn-dev w-100">
    <button class="btn btn-square btn_default  mt-3  w-100  " (click)=" !loader && saveLocation()">
      <span *ngIf="!loader"> {{'L_chooseThisLocation'|translate}}</span>
      <div class="spinner-border" *ngIf="loader"></div>
    </button>
  </div>

</div>
