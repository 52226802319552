import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MainService } from './main.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable()
export class TokenInterceptorService implements HttpInterceptor {



  constructor(private injector: Injector,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,

  ) { }

  intercept(req, next) {
    console.log("🚀 ~ file: token-interceptor.service.ts:22 ~ TokenInterceptorService ~ intercept ~ req:", req)

    let mainService = this.injector.get(MainService);
    let tokenizedReq;
    if (req.url.includes('maps.googleapis.com')) {
      tokenizedReq = req.clone({
      })
    } else {
      // let pass ='asdasfas da sda '
      tokenizedReq = req.clone({
        setHeaders: {
          'accept-language': mainService.selected_lang.lang,
          'Access-Control-Allow-Origin': '*',
        }
      })


    }
    return next.handle(tokenizedReq)
      .pipe(
        map((res: any) => {
          if (res.status == 200) {
          }
          return res
        }),
      )

  }
  // showError(err) {

  //   console.log("🚀 ~ file: token-interceptor.service.ts:47 ~ TokenInterceptorService ~ showError ~ snackBar", this.snackBar.open(this.translateService.instant('L_errorConnection'), '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 }))

  //   if (err.status == 0) {
  //     this.snackBar.open(this.translateService.instant('L_errorConnection'), '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
  //   }
  //   else {
  //     if (err.error) {

  //       if (err.error.message) {
  //         this.snackBar.open(err.error.message, '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
  //       } else {
  //         this.snackBar.open(this.translateService.instant('L_someErrorHappen'), '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
  //       }
  //     }

  //   }
  // }


}
