
<div id="overlay" class="loading_data" *ngIf="noStoreFound">
  <img src="assets/imgs/error.png" alt="No Store Found">
  <br />
  <h2 *ngIf="errorMessage">
    {{errorMessage}}
  </h2>
  <h2 *ngIf="!errorMessage">
    {{'L_no_restaurant_with_name'|translate}}
  </h2>
  <p>
    {{'L_make_sure_correct_link'| translate}}
  </p>
</div>

<app-resturant-info></app-resturant-info>
<section class="rest_home">
  <div class="cat_sec">
    <div class="container">
      <div #widgetsContent class="categories_sec">
        <ng-container *ngFor="let category of categories ;let i = index">
          <!-- <ng-template carouselSlide [id]="category.id"> -->
          <div class="categories_item" [ngClass]=" category.id == selectedCateoryID ? 'active' : '' "
            (click)="scrollToCategory(category.id)" id="cat_{{category.id}}Head">
            <img [src]="this.mainService.uploadPath+category.photo" [alt]="category.name">
            <!-- <i class='bx {{slide.icon}}'></i> -->
            <!-- <h5>{{slide.name[mainServ.lang]}}</h5> -->
            <h5>{{category.translated_name}}</h5>
          </div>
          <!-- </ng-template> -->
        </ng-container>
      </div>
    </div>
  </div>

  <div class="container">
    <ng-container *ngFor="let category of categories; let i = index">
      <div class="meel_list" id="cat_{{category.id}}">
        <div class="category_title">
          <h2>{{category.translated_name}}</h2>
          <!-- <i class='bx bx-down-arrow-alt' (click)="toggleView(i)"></i> -->
        </div>
        <div class="cat_list open">
          <div class="row">
            <div class="col-6 col-lg-3 col-md-6 col-sm-6" *ngFor="let mealItem of category.items">
              <app-meal-item [mealData]="mealItem"></app-meal-item>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</section>
