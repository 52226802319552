<!-- <button mat-raised-button color="primary"
    matBadge="8" matBadgePosition="before" matBadgeColor="accent" (click)="open(content)">
    <i class='bx bx-cart' ></i>
</button> -->

<button class="btn btn-lg" (click)="open(content)">
  <i class='bx bx-cart'></i>
  <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
    *ngIf="cartService.shoppingCartItems.length > 0">
    {{cartService.shoppingCartItems.length}}
  </span>
</button>

<ng-template #content let-offcanvas>
  <div class="offcanvas-header cart_header">
    <h4 class="offcanvas-title" id="offcanvas-basic-title">{{'L_Shopping_cart'|translate}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss()"></button>
  </div>
  <div class="offcanvas-body">
    <div class="cart_sec">

      <div class="cart_empty" *ngIf="!this.show_success_order_message &&  !cartService.shoppingCartItems.length">
        <div class="inner text-center">
          <img class="mb-3" src="assets/imgs/empty-cart.png" alt="cart empty">
          <h2>{{'L_Browse_products_add_items_cart'|translate}}</h2>
          <button class="btn btn-square btn_default continue_shop mt-4" (click)="dismissMenu()">
            {{'L_continue_shopping'|translate}} </button>
        </div>
      </div>


      <div class="cart_success" *ngIf="this.show_success_order_message && !cartService.shoppingCartItems.length ">
        <div class="inner text-center">
          <img class="mb-3" src="assets/imgs/success.png" alt="cart empty">
          <h2>{{'L_request_successfully_sent_restaurant'|translate}}</h2>
          <button class="btn btn-square btn_default continue_shop mt-4"
            (click)="dismissMenu()">{{'L_continue_shopping'|translate}}</button>
        </div>
      </div>

      <div class="cart_data" *ngIf="cartService.shoppingCartItems.length > 0">
        <div class="cart_item" *ngFor="let item of cartService.shoppingCartItems; let  i = index">
          <div class="d-flex align-item-center">

            <button class="btn btn_default delete_item" (click)="cartService.removeProduct(i)"> <i
                class='bx bx-trash'></i></button>
            <div class="image">
              <img *ngIf="item.photo" class="img-fluid" [src]="mainService.uploadPath +item.photo" alt="">
              <img class="img" *ngIf="!item.photo" src="assets/imgs/default-img.jpg" alt="default meal" />
            </div>
            <div class="item_data">
              <h1 class="mb-0">{{item.name}}</h1>
              <p>
                {{item.description}}
              </p>

              <p class="size" *ngIf="!item.has_one_size"><span>{{'L_size'|translate}} : </span>
                {{item.selectedSize['translated_name']}}
              </p>
            </div>
          </div>
          <div class="item_data">
            <div class="addons" *ngIf="item.addons.length">
              <h2>{{'L_Addons'|translate}}</h2>
              <div class="addon_item" *ngFor="let addon of item.addons">

                <span class="addon_name">
                  {{addon.name}}
                </span>
                <span> {{addon.price | number }} ₺</span>
                <div class="quantity">
                  <div class="d-inline-flex align-item-center">
                    <span class="dec quantity_btn" (click)="cartService.decrementAddon(item ,addon)"><i
                        class='bx bx-minus'></i></span>
                    <input type="text" value="1" [(ngModel)]="addon.count" name=" quantity" disabled="true">
                    <span class="inc quantity_btn" (click)="cartService.incrementAddon(item ,addon)"><i
                        class='bx bx-plus'></i></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="quantity">
              <div class="d-inline-flex align-item-center">
                <span class="dec quantity_btn" (click)="cartService.decrement(item)"><i class='bx bx-minus'></i></span>
                <input type="text" [value]="item.count" [(ngModel)]="item.count" name="meelNum" disabled="true">
                <span class="inc quantity_btn" (click)="cartService.increment(item)"><i class='bx bx-plus'></i></span>
              </div>
              <span class="price">{{item.itemTotalPrice}}</span>
            </div>

          </div>
        </div>
      </div>


      <!-- <form (ngSubmit)="onCartOrder()" #form="ngForm">
      </form> -->
    </div>
  </div>
  <div class="offcanvas-footer cart_footer" *ngIf="cartService.shoppingCartItems.length > 0">
    <div class="d-flex align-item-center">
      <div class="total_price">
        <h4>{{'L_total_price'|translate}}</h4>
        <h2>{{ cartService.total_pirce_all_cart |number }} ₺</h2>
      </div>
      <a *ngIf="this.mainService.tableId" class="btn btn-square btn_default continue_shop"
        (click)="openItemModal(TableDataModal)">{{'L_confirm_order'|translate}}</a>
      <a *ngIf="!this.mainService.tableId" class="btn btn-square btn_default continue_shop"
        (click)="openItemModal(DataModal)">{{'L_confirm_order'|translate}}</a>
    </div>
  </div>
</ng-template>



<ng-template #DataModal let-modal class="custom_modal">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'L_Delivery_data'|translate}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
      <i class="bx bx-x"></i>
    </button>
  </div>
  <div class="modal-body" *ngIf="modelOpened">

    <!-- <div class="error_message" *ngIf="!userForm.valid">
            <i class='bx bx-error-circle'></i>
            <span>{{'L_error_occurred_please_check_data'|translate}}</span>
        </div> -->



    <form [formGroup]="userForm" (ngSubmit)="!this.addOrder && sendOrder()">






      <div class="mb-2">
        <label for="name" class="form-label">{{'L_name'|translate}} <span>*</span></label>
        <input required type="text" class="form-control" id="name" formControlName="name">
        <p class="form-text error_msg" *ngIf="name?.touched && name?.invalid">{{'L_MoreThan4char'|translate}}</p>
      </div>

      <div class="mb-2">
        <label for="customer_mobile" class="form-label">{{'L_Phone'|translate}} <span>*</span></label>
        <input required placeholder="*** *** ** **" type="tel" class="form-control" id="mobile"
          formControlName="mobile">
        <p class="form-text error_msg" *ngIf="mobile?.touched && mobile?.invalid">{{'L_phone_not_available'|translate}}
        </p>
      </div>

      <div class="mb-2" (click)="openMapLocationModal(MapModal)">
        <label for="location" class="form-label">{{'L_Location_map'|translate}}
          <span>*</span></label>

        <input type="text" class="form-control" id="name" readonly placeholder="{{'L_chooseThisLocationMsg'|translate}}"
          formControlName="locationOnMapAddress">

        <!-- <div class="form-check" (click)="chooseTypeOfMap('determaionMyLocationAuto');">
          <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
          <label class="form-check-label" for="exampleRadios1">
            {{'L_determaionMyLocationAuto'|translate}}
          </label>
        </div>
        <div class="form-check" (click)="chooseTypeOfMap ('determaionAtherLocation')">
          <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
          <label class="form-check-label" for="exampleRadios2">
            {{'L_determaionAtherLocation'|translate}}
          </label>
        </div> -->
        <!-- <div class="location_on_map mt-2 " id="mapLocation"  >
          <app-goolge-map-co [latitude]="this.orderData.customer_lat" [longitude]="this.orderData.customer_lng"
            (locaiotnChanged)="setNewLocation($event)">
          </app-goolge-map-co>
        </div> -->
        <p class="form-text error_msg" *ngIf="error_sprate.lat_lng">{{'L_This_field_required'|translate}}</p>
      </div>

      <div class="mb-2">
        <label for="customer_address" class="form-label">{{'L_Address'|translate}} <span>*</span></label>
        <input required type="text" class="form-control" id="customer_address" formControlName="address">
        <p class="form-text error_msg" *ngIf="address?.touched && address?.invalid">
          {{'L_This_field_required'|translate}}</p>
      </div>
      <div class="mb-2">
        <label for="building_no" class="form-label">{{'L_building_number'|translate}} </label>
        <input type="text" class="form-control" id="building_no" formControlName="building_no">
      </div>
      <div class="mb-2">
        <label for="apartment_no" class="form-label">{{'L_Apartment_number'|translate}} </label>
        <input type="text" class="form-control" id="apartment_no" formControlName="apartment_no">
      </div>



      <div class="mb-3 form-check" [hidden]="!this.storedUserDataChanged">
        <input type="checkbox" class="form-check-input" id="mainData" formControlName="mainData">
        <label class="form-check-label" for="mainData">{{'L_main_data'|translate}}</label>
      </div>


      <div class="order_summary" *ngIf="cartService.shoppingCartItems.length > 0">
        <ul>
          <li class="item_price"> <span>{{'L_products_price'|translate}} : </span> <span>{{
              cartService.total_pirce_all_cart |number }} ₺ </span></li>
          <li class="delivery_price"> <span>{{'L_Delivery_price'|translate}} : </span>
            <span>{{ deliveryPrice |number }} ₺</span>
          </li>
          <li class="vat" *ngIf="0"> <span>{{'L_Tax'|translate}} : </span> <span></span></li>
          <li class="total_price"> <span> {{'L_total_price'| translate}} : </span> <span>{{
              cartService.total_pirce_all_cart + deliveryPrice |number}} ₺</span></li>
        </ul>
      </div>

      <p class="form-text error_msg" *ngIf="this.show_error_validation_form">{{'L_someErrorForm'|translate}}</p>

      <button [disabled]="this.addOrder " class="btn btn-square btn_default  mt-3 w-100" type="submit">
        {{'L_sendOrder'|translate}}</button>
    </form>


  </div>
</ng-template>


<ng-template #TableDataModal let-modal class="custom_modal">
  <div class="modal-header ">
    <h4 class="modal-title" id="modal-basic-title">{{'L_sendOrder'|translate}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
      <i class="bx bx-x"></i>
    </button>
  </div>
  <div class="modal-body" *ngIf="modelOpened">

    <!-- <div class="error_message" *ngIf="!userForm.valid">
            <i class='bx bx-error-circle'></i>
            <span>{{'L_error_occurred_please_check_data'|translate}}</span>
        </div> -->

    <form [formGroup]="userTableForm" (ngSubmit)="!this.addOrder && sendTetableOrder()">
      <div class="mb-2">
        <label for="name" class="form-label">{{'L_name'|translate}} <span>*</span></label>
        <input required type="text" class="form-control" id="name" formControlName="name">
        <p class="form-text error_msg" *ngIf="nameOfTableUser?.touched && nameOfTableUser?.invalid">
          {{'L_MoreThan4char'|translate}}</p>
      </div>

      <div class="mb-2">
        <label for="customer_mobile" class="form-label">{{'L_Phone'|translate}} <span>*</span></label>
        <input required placeholder="*** *** ** **" type="tel" class="form-control" id="mobile"
          formControlName="mobile">
        <p class="form-text error_msg" *ngIf="mobileOfTableUser?.touched && mobileOfTableUser?.invalid">
          {{'L_phone_not_available'|translate}}
        </p>
      </div>

      <div class="order_summary" *ngIf="cartService.shoppingCartItems.length > 0">
        <ul>
          <li class="item_price"> <span>{{'L_products_price'|translate}} : </span> <span>{{
              cartService.total_pirce_all_cart |number }} ₺ </span></li>
          <li class="vat" *ngIf="0"> <span>{{'L_Tax'|translate}} : </span> <span></span></li>
          <li class="total_price"> <span> {{'L_total_price'| translate}} : </span> <span>{{
              totalTablePrice |number}} ₺</span></li>
        </ul>
      </div>

      <p class="form-text error_msg" *ngIf="this.show_error_validation_form">{{'L_someErrorForm'|translate}}</p>

      <button [disabled]="this.addOrder" class="btn btn-square btn_default  mt-3 w-100" type="submit">
        {{'L_sendOrder'|translate}}
      </button>
    </form>


  </div>
</ng-template>


<ng-template #MapModal let-modal class="custom_modal">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'L_Location_map'|translate}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
      <i class="bx bx-x"></i>
    </button>
  </div>
  <div class="modal-body pt-1 p-2  " *ngIf="modelMapOpened">
    <!-- <div class="error_message" *ngIf="!userForm.valid">
            <i class='bx bx-error-circle'></i>
            <span>{{'L_error_occurred_please_check_data'|translate}}</span>
        </div> -->

    <app-goolge-map-co [latitude]="this.orderData.customer_lat" [longitude]="this.orderData.customer_lng"
      (locaiotnChanged)="setNewLocation($event) ;modal.dismiss() ">
    </app-goolge-map-co>


  </div>
</ng-template>
