import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { MealItemComponent } from './components/meal-item/meal-item.component';
import { ResturantInfoComponent } from './components/resturant-info/resturant-info.component';
import { HomeComponent } from './pages/home/home.component';
import { CartComponent } from './pages/cart/cart.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { BgColorDirective } from './directives/bg-color.directive';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { MyOrdersComponent } from './pages/my-orders/my-orders.component';


import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ChangeLanguageComponent } from './components/change-language/change-language.component';
 
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { SimpleSmoothScrollModule } from 'ng2-simple-smooth-scroll';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoolgeMapModuleComp } from './components/goolge-map-co/goolge-map-co.module';
import { TokenInterceptorService } from './services/token-interceptor.service';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    MealItemComponent,
    ResturantInfoComponent,
    HomeComponent,
    CartComponent,
    BgColorDirective,
    ChangeLanguageComponent,
    ScrollToTopComponent,
    MyOrdersComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
 
    HttpClientModule,
    ReactiveFormsModule,
    GoolgeMapModuleComp,
    NgbPaginationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    SimpleSmoothScrollModule,

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
