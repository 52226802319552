import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule, NgForm } from '@angular/forms';
import { GoolgeMapComponentComp } from './goolge-map-co.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule,
        TranslateModule
    ], exports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        GoolgeMapComponentComp
    ], declarations: [
        GoolgeMapComponentComp
    ]
})
export class GoolgeMapModuleComp {

}