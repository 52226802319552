import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { count, map } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subscriber, interval } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AddonInCart, ProductInCart } from '../modals/order';
import { MainService } from './main.service';

// Get product from Localstorage

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public shoppingCartItems = [];
  total_pirce_all_cart: number = 0
  constructor(public snackBar: MatSnackBar,
    public translateService: TranslateService,
    public mainService: MainService
  ) {
  }

  removeProduct(product_index) {
    this.shoppingCartItems.splice(product_index, 1)
    this.calcTotalAmount()
  }

  removeAllCartProducts() {
    this.shoppingCartItems = [];
    // this.shoppingCartItems.splice(0, this.shoppingCartItems.length);
    this.localStorage().setObject(this.mainService.storeData.id + 'shoppingCartItems', this.shoppingCartItems);
    this.calcTotalAmount();
  }
  // Add to cart
  public addToCart(product) {

    product.size = product.has_one_size ? 0 : product.selectedSize.id;
    product.price = product.has_one_size ? product.price : product.selectedSize.price;
    product.all_addons_count = false
    for (let index = 0; index < product.addons.length; index++) {
      product.all_addons_count += product.addons[index].count;
    }
    console.log("🚀 ~ file: cart.service.ts:69 ~ CartService ~ addToCart ~ all_addons_count", product)
    let product_index = this.shoppingCartItems.findIndex(e => e.id == product.id && e.size == product.size && e.all_addons_count == 0 && product.all_addons_count == 0)
    if (product_index > -1) {
      this.shoppingCartItems[product_index]['count']++
    } else {
      this.pushNewItem(product)
    }
    this.snackBar.open(this.translateService.instant('L_addProductToCart'), '', { panelClass: 'success', verticalPosition: 'bottom', duration: 1000 });
    this.calcTotalAmount();

  }
  pushNewItem(product) {
    let product_to_cart: ProductInCart = {
      id: product.id,
      count: product.count,
      addons: [],
      size: product.size,
      name: product.translated_name,
      description: product.translated_description,
      photo: product.photo,
      price: product.price,
      selectedSize: product.selectedSize,
      itemTotalPrice: product.itemTotalPrice,
      has_one_size: product.has_one_size,
      all_addons_count: product.all_addons_count,

    }
    product.addons.forEach(e => {
      let addon: AddonInCart = {
        id: e.id,
        count: e.count,
        price: e.price,
        name: e.translated_name
      }
      product_to_cart.addons.push(addon);
    })
    this.shoppingCartItems.push(product_to_cart);

  }
  public calcTotalAmount() {
    this.total_pirce_all_cart = 0
    for (let index = 0; index < this.shoppingCartItems.length; index++) {
      this.total_pirce_all_cart += this.calTotalItemPrice(this.shoppingCartItems[index]);
    }
    // console.log("🚀 ~ file: cart.service.ts:65 ~ CartService ~ calcTotalAmount ~ this.total_pirce_all_cart ", this.mainService.storeData );
    this.total_pirce_all_cart = Math.round(this.total_pirce_all_cart * 1000) / 1000;
    this.localStorage().setObject(this.mainService.storeData.id + 'shoppingCartItems', this.shoppingCartItems);
  }
  increment(product) {
    product.count++;
    this.calTotalItemPrice(product);
    this.calcTotalAmount()
  }
  decrement(product) {
    if (product.count > 1) {
      product.count--;
      this.calTotalItemPrice(product);
      this.calcTotalAmount()
    }
  }
  decrementAddon(product, addon: any) {
    if (addon.count > 0) {
      addon.count--;
      this.calTotalItemPrice(product);
      this.calcTotalAmount()
    }
  }
  incrementAddon(product, addon: any) {
    addon.count++;
    this.calTotalItemPrice(product);
    this.calcTotalAmount()
  }

  calTotalItemPrice(product) {
    product.itemTotalPrice = 0;
    product.itemAddonsPrice = 0;
    // console.log("🚀=============================================================", product);
    if(!product.has_one_size){
    product.itemTotalPrice = product.selectedSize.price ? product.selectedSize.price * product.count : 0;
    }
    else{
    product.itemTotalPrice = product.price ? product.price * product.count : 0;
    }
    product.addons = product.addons ? product.addons : [];
    for (let index = 0; index < product.addons.length; index++) {
      product.itemAddonsPrice += Math.round((product.addons[index].price * product.addons[index].count) * 1000) / 1000;
    }
    // console.log("🚀 ~ file: cart.service.ts:174 ~ CartService ~ calTotalItemPrice ~ product.selectedSize", product.itemTotalPrice)
    product.itemTotalPrice += product.itemAddonsPrice ? product.itemAddonsPrice : 0;
    product.itemTotalPrice = Math.round(product.itemTotalPrice * 1000) / 1000;
    return product.itemTotalPrice;
  }

  localStorage() {
    return {
      set: function (key, value) {
        window.localStorage[key] = value;
      },
      get: function (key) {
        return window.localStorage[key] || '';
      },
      setObject: function (key, value) {
        window.localStorage[key] = JSON.stringify(value);
      },
      getObject: function (key) {
        return JSON.parse(window.localStorage[key] || '{}');
      }
    };
  }




}
