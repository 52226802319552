import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter, forkJoin } from 'rxjs';
import { CartService } from './services/cart.service';
import { MainService } from './services/main.service';
import { HomeService } from './services/home.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'electronic-menu';
  loading: boolean = true;
  urlArr: any;
  event_: any


   public  primary_color = "#fff" ;
  public secondary_color = "#fff";
  constructor(
    public translate: TranslateService,
    public mainService: MainService,
    public cartService: CartService,
    public homeServ: HomeService,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
     private el: ElementRef
  ) {
    this.translate.use(this.mainService.defualt_lang.lang);
    this.mainService.selected_lang = this.mainService.localStorage().getObject("selected_lang") // {} , ''
    this.mainService.chooseLanguage(this.mainService.selected_lang, false);
  }
  async ngOnInit() {
    // this.setLangageFromStorage()
    this.mainService.getSomeData();
    this.event_ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      let currentPath = this.route.root.firstChild?.snapshot.url.join('/');
      this.getSlugFromUrl(currentPath)
      console.log('Current Path:', currentPath);
      this.getResturantData()
      this.event_.unsubscribe()
    });
  }
  getSlugFromUrl(currentPath) {
    this.urlArr = currentPath.split('/');
    this.mainService.getSlugFromUrl(this.urlArr);
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 30 ||
      document.documentElement.scrollTop > 30) {
      document.getElementById('navbar').classList.add('active');
    }
    else {
      document.getElementById('navbar').classList.remove('active');
    }
  }

  getResturantData() {
    this.loading = true;
    this.homeServ.getResturantData(this.mainService.storeSlug).subscribe(
      async (res) => {
        this.mainService.storeData = res.record;
        this.mainService.storeData.primary_color = this.mainService.storeData.primary_color ? this.mainService.storeData.primary_color : '#112a4f'
        this.mainService.storeData.secondary_color = this.mainService.storeData.secondary_color ? this.mainService.storeData.secondary_color : '#f7941d'
        this.loading = false;
        //  let body_element = document.getElementsByTagName('body') as any;
        // console.log("🚀 ~ file: app.component.ts:71 ~ AppComponent ~ body_element:", body_element)

          
        // this.renderer.setStyle(document.body, '--secondary_color', this.mainService.storeData.secondary_color);
        // this.renderer.setStyle(this.el.nativeElement.ownerDocument.body, '--app-color', '#eee');

        document.body.style.setProperty("--primary_color", this.mainService.storeData.primary_color);
        document.body.style.setProperty("--secondary_color", this.mainService.storeData.secondary_color);

        // body_element.style['--secondary_color'] = this.mainService.storeData.secondary_color
        // body_element.style['--primary_color'] = this.mainService.storeData.primary_color
    


        // this.mainService.localStorage().setObject('storeData', this.storeData);
        this.cartService.shoppingCartItems = await this.mainService.localStorage().getObject(this.mainService.storeData.id + "shoppingCartItems")
        this.cartService.shoppingCartItems = Object.keys(this.cartService.shoppingCartItems).length !== 0 ? this.cartService.shoppingCartItems : []
        this.cartService.calcTotalAmount()
      },
      (error) => {
        this.loading = false;
      }
    )
  }



}
