import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MainService } from './../../services/main.service';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss']
})
export class MyOrdersComponent implements OnInit {

  params = {
    page: 1
  }
  ordersData: any = {};
  selectedLang: any;
  pageLoader: boolean;

  constructor(public mainService: MainService, private modalService: NgbModal,

    public snackBar: MatSnackBar,
    public translateService: TranslateService) {

    this.selectedLang = this.mainService.selected_lang.lang;
  }

  ngOnInit(): void {

  }

  openOrdersList(content) {
    this.getPreviousOrders();
    this.modalService.open(content, { fullscreen: true, windowClass: "orders_modal" });
  }

  getPreviousOrders() {
    this.pageLoader = true;
    this.mainService.getPreviousOrders(this.mainService.device_key, this.params).subscribe((res: any) => {
      this.ordersData = res.records;
      this.pageLoader = false;
      // console.log(this.previousOrders);
    },
      (err) => {
        this.pageLoader = false;
      })
  }
  pageChange(number) {
    this.params.page = number
    this.getPreviousOrders()
    console.log("🚀 ~ file: my-orders.component.ts:46 ~ MyOrdersComponent ~ pageChange ~ number", number)

  }
  getSingleOrderData(index) {
    this.ordersData.data[index]['detailsOpend'] = !this.ordersData.data[index]['detailsOpend'];
    // console.log('=======================', this.ordersData.data[index]['detailsOpend']);
    if (!this.ordersData.data[index]['orderData']) {
      this.ordersData.data[index]['loader'] = true;
      this.mainService.getSingleOrderData(this.mainService.device_key, this.ordersData.data[index]['id']).subscribe((res) => {
        this.ordersData.data[index]['orderData'] = res.record;
        this.ordersData.data[index]['loader'] = false;
      }, err => {
        if (err.status == 0) {
          this.snackBar.open(this.translateService.instant('L_errorConnection'), '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
        }
        else {
          if (err.error) {

            if (err.error.message) {
              this.snackBar.open(err.error.message, '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
            } else {
              this.snackBar.open(this.translateService.instant('L_someErrorHappen'), '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
            }
          }

        }
      })
    }
  }

  sendAlarm(orderId){
    this.mainService.doAlarm(orderId).subscribe((res) => {
      this.snackBar.open(this.translateService.instant('L_alarm_sended'), '', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
    })
  }



}
