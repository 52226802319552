import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
 
import { HomeService } from 'src/app/services/home.service';
import { MainService } from 'src/app/services/main.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild("widgetsContent", { read: ElementRef })
  public widgetsContent: ElementRef<any>;

  // storeSlug: any;
  // imageUrl="https://staging.akillikurye.com/storage/";
 
  categories: any;
  menuData: any;
  urlArr: any;
  loading: boolean = false;
  noStoreFound: any;
  cat_: any = [];
  errorMessage: any;
  selectedCateoryID: any
  diabledAutoActiveScroll: any;
  onScrollTimeOut: any;
  onScrollYTimeOut: any;
  diabledAutoActiveScrollTimeOut: any;
  constructor(
    private homeServ: HomeService,
    private route: ActivatedRoute,

    public mainService: MainService,
    public snackBar: MatSnackBar,
    public translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    //   this.categories = [
    //     {id:'1',name:'cat1',src:'assets/img/categories/cat1.png', icon:'bx-bowl-hot'},
    //     {id:'2',name:'cat2',src:'assets/img/categories/cat2.png', icon:'bxs-drink'},
    //     {id:'3',name:'cat3',src:'assets/img/categories/cat3.png', icon:'bx-baguette'},
    //     {id:'4',name:'cat4',src:'assets/img/categories/cat4.png', icon:'bxs-popsicle'},
    //     {id:'5',name:'cat5',src:'assets/img/categories/cat5.png', icon:'bx-dish'},
    //     {id:'6',name:'cat6',src:'assets/img/categories/cat6.png', icon:'bxs-bowl-rice'},
    // ];
    this.getAllMenuData();

  }

  getAllMenuData() {
    this.loading = true;
    this.homeServ.getAllMenuData(this.mainService.storeSlug).subscribe(
      (res) => {
        this.menuData = res.menu;
        this.categories = this.menuData[0].categories;
        // this.storeData = res.store;
        this.loading = false;
        // console.log(this.categories);
        // this.categories.forEach((category: any) => {
        //   this.cat_ = category.items;
        //   console.log(category.items , this.cat_ );
        // });
      },
      (err) => {
        // console.log('============================',this.errorMessage);
        this.loading = false;
        this.noStoreFound = true;

        if (err.status == 0) {
          this.snackBar.open(this.translateService.instant('L_errorConnection'), '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
        }
        else {
          if (err.error) {

            if (err.error.message) {
              this.errorMessage = err.error.message;
              this.snackBar.open(err.error.message, '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
            } else {
              this.snackBar.open(this.translateService.instant('L_someErrorHappen'), '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
            }
          }

        }

      }
    )
  }


  //scroll to spacific category
  // scrollToCategory(cat_id: any) {
  //   this.selectedCateoryID = cat_id
  //   const element = document.querySelector('#cat_' + cat_id);
  //   const y = element.getBoundingClientRect().top + window.pageYOffset - 180;
  //   window.scrollTo({ top: y, behavior: 'smooth' });

  // }
  changed(OWlevent) {
    console.log(OWlevent)
  }


  // @HostListener('scroll', ['$event']) // for scroll events of the current element
  @HostListener("window:scroll", ["$event"]) // for window scroll events
  onScroll(event) {
    if (this.diabledAutoActiveScroll) {
      return;
    }
    if (this.onScrollTimeOut) {
      clearTimeout(this.onScrollTimeOut);
    }
    this.onScrollTimeOut = setTimeout(() => {
      if (this.categories?.length > 0) {
        this.categories.forEach((element: any) => {
          if (element.id) {
            // this.selectedCateoryID = element.id.toString();
            let div = document.getElementById("cat_" + element.id);
            var distanceFromTop = div.getBoundingClientRect().top + window.pageYOffset - 180;
            if (window.pageYOffset >= distanceFromTop) {
              this.selectedCateoryID = element.id ;
              this.scrollY(element.id);
            }
            // console.log("🚀 ~ file: menu.component.ts:226 ~ MenuComponent ~ this.categories.forEach ~ distanceFromTop:", distanceFromTop)
          }
        });
      }
    }, 30);
  }
  scrollY(id) {
    if (this.onScrollYTimeOut) {
      clearTimeout(this.onScrollYTimeOut);
    }
    this.onScrollYTimeOut = setTimeout(() => {
      let catTitle = document.getElementById("cat_" + id + "Head");
      var distanceFromleft = catTitle.getBoundingClientRect().left;

      this.widgetsContent.nativeElement.scroll({
        top: 0,
        left:
          this.widgetsContent.nativeElement.scrollLeft + distanceFromleft - 150,
        behavior: "smooth",
      });
    }, 30);
  }
  scrollToCategory(id) {
    console.log("🚀 ~ file: home.component.ts:183 ~ HomeComponent ~ scrollToCategory ~ id:", id)
    this.diabledAutoActiveScroll = true;
    this.selectedCateoryID = id;
    let div = document.getElementById('cat_' + this.selectedCateoryID);
    var distanceFromTop = div.getBoundingClientRect().top + window.pageYOffset - 180;
    window.scroll({
      top: distanceFromTop,
      left: 0,
      behavior: "smooth",
    });
    this.scrollY(id);

    clearTimeout(this.diabledAutoActiveScrollTimeOut);
    this.diabledAutoActiveScrollTimeOut = setTimeout(() => {
      this.diabledAutoActiveScroll = false;
    }, 1000);
  }

}
