import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { HomeService } from 'src/app/services/home.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-resturant-info',
  templateUrl: './resturant-info.component.html',
  styleUrls: ['./resturant-info.component.scss']
})
export class ResturantInfoComponent implements OnInit {
  // @Input() storeData: any;
  // storeData: any;
  constructor(public mainService:MainService , private homeServ :HomeService, private router:Router , private cartService : CartService) { }

  ngOnInit(): void {
    // ;
    
  }

 


}
