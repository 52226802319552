import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation, AfterViewInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { MainService } from 'src/app/services/main.service';



declare var jquery: any;
declare var $: any;
declare var google: any;
@Component({
  selector: "app-goolge-map-co",
  templateUrl: "./goolge-map-co.component.html",
  styleUrls: ['./goolge-map-co.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GoolgeMapComponentComp implements OnInit {

  @Output() locaiotnChanged = new EventEmitter<any>();
  @Input('latitude') latitude: any;
  @Input('longitude') longitude: any;
  @Input('locationOnMapAddress') locationOnMapAddress: any;
  zoom: number = 17;

  iconBase = 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png';

  marker: any;
  @ViewChild('gmap') gmapElement: any;
  map: any;
  defulteLatitude: any = '41.035851'
  defulteLongitude: any = '28.911881'
  locationOnAddressTimeOut: any;
  loader: boolean;


  constructor(
    public mainService: MainService,
    private http: HttpClient,

  ) {
  }
  ngOnInit() {
    //console.log("this.latitude : " + this.latitude, "this.longitude : " + this.longitude)

    console.log("this.latitude" + this.latitude, "this.longitude" + this.longitude)
    this.map = google.maps.Map;

    this.showMap()
    if (!this.latitude && !this.longitude) {
      this.getCurrentLocation()
    }

  }

  getCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {

        this.latitude = position.coords.latitude ? position.coords.latitude : this.defulteLatitude;
        this.longitude = position.coords.longitude ? position.coords.longitude : this.defulteLongitude;
        // this.setMarker()
        this.map.setCenter(new google.maps.LatLng(this.latitude, this.longitude));
        this.setAddressAfterTwoSec()

      });
    } else {
      this.latitude = this.latitude ? this.latitude : this.defulteLatitude;
      this.longitude = this.longitude ? this.longitude : this.defulteLongitude;
      // this.setMarker()
      this.map.setCenter(new google.maps.LatLng(this.latitude, this.longitude));

    }
  }
  showMap() {
    setTimeout(() => {
      let mapProp = {
        mapTypeControl: false,
        center: new google.maps.LatLng(this.latitude, this.longitude),
        zoom: this.zoom,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        gestureHandling: "greedy"
      };
      this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

      if (this.latitude && this.longitude) {
        // this.setMarker()
        this.map.setCenter(new google.maps.LatLng(this.latitude, this.longitude));
        this.map.setZoom(this.zoom)

      } else {
        this.getCurrentLocation()
      }

      this.map.addListener("center_changed", () => {
        // 3 seconds after the center of the map has changed, pan back to the
        // marker.
        this.latitude = this.map.getCenter().lat();
        this.longitude = this.map.getCenter().lng();
        this.setAddressAfterTwoSec()
      });


      // this.map.addListener("click", (mapsMouseEvent) => {
      //   let new_location = mapsMouseEvent.latLng.toJSON()
      //   this.latitude = new_location.lat
      //   this.longitude = new_location.lng
      //   // this.marker.setMap(null);
      //   // this.setMarker()
      //   this.map.setCenter(new google.maps.LatLng(this.latitude, this.longitude));

      // })


      //search input Start
      // document.getElementById('my-button').onclick = function () {
      //     var input = document.getElementById('pac-input');

      //     google.maps.event.trigger(input, 'focus', {});
      //     google.maps.event.trigger(input, 'keydown', { keyCode: 13 });
      //     google.maps.event.trigger(this, 'focus', {});

      // };



      let input: any = document.getElementById('pac-input');
      let searchBox = new google.maps.places.SearchBox(input);
      //        this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

      searchBox.addListener('places_changed', () => {
        let places = searchBox.getPlaces();
        //console.log("place changed", places);

        if (places.length == 0) {
          return;
        }
        // For each place, get the icon, name and location.
        let bounds = new google.maps.LatLngBounds();
        places.forEach((place) => {
          if (!place.geometry) {
            //console.log("Returned place contains no geometry");
            return;
          }

          // Create a marker for each place.

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();

            // this.setMarker()
            this.map.setCenter(new google.maps.LatLng(this.latitude, this.longitude));
            this.map.setZoom(this.zoom)


          } else {
            bounds.extend(place.geometry.location);
          }
        });
        this.map.fitBounds(bounds);
      });
    }, 500);

  }
  // setMarker() {
  //   if ('setCenter' in this.map) {
  //     if (this.marker) {
  //       this.marker.setMap(null);
  //     }
  //     let location = new google.maps.LatLng(this.latitude, this.longitude);
  //     this.marker = new google.maps.Marker({
  //       position: location,
  //       map: this.map,
  //       icon: this.iconBase,
  //       title: 'Got you!'
  //     });

  //     this.map.setCenter(new google.maps.LatLng(this.latitude, this.longitude));
  //   }

  //   // emit To parent Page

  // }

  saveLocation() {
    let loc = { "latitude": this.latitude, "longitude": this.longitude, "locationOnMapAddress": this.locationOnMapAddress }

    console.log("🚀 ~ file: goolge-map-co.component.ts:171 ~ GoolgeMapComponentComp ~ saveLocation ~ loc:", loc)
    this.locaiotnChanged.emit(loc);
  }
  setAddressAfterTwoSec() {
    this.loader = true
    clearTimeout(this.locationOnAddressTimeOut)
    this.locationOnAddressTimeOut = setTimeout(() => {
      this.getMapAddress()
    }, 1000);
  }
  async getMapAddress(): Promise<any> {
    let lang = this.mainService.selected_lang.lang ? this.mainService.selected_lang.lang : 'ar';
    let geocodeUrl = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + this.latitude + ',' + this.longitude + '&language=' + lang + '&key=AIzaSyAuBgi9AeZeDIUjV3fdvC8lASnpmwoxuvY';
    // return await new Promise(resolve => {
    this.http.get(geocodeUrl).subscribe((data: any) => {
      if (data) {
        // code...
        if (data.results[0]) {
          // code...
          // resolve(data.results[2].formatted_address)

          if (data.results[0].address_components?.length > 0) {
            this.locationOnMapAddress = ''
            data.results[0].address_components.forEach(element => {

              if (element?.types[0] != 'country' && element?.types[0] != 'postal_code') {
                this.locationOnMapAddress += element.long_name + " "
              }
            });
          }
          this.loader = false

        }
      }
      // });
    });


  }

}
