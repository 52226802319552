<div class="meal_item text-center rounded overflow-hidden" (click)="openItemModal(ItemModal)">
  <div class="  overflow-hidden ">
    <img *ngIf="mealData.photo" class="img-fluid" [src]="mainService.uploadPath +mealData.photo" alt="">
    <img class="img" *ngIf="!mealData.photo" src="assets/imgs/default-img.jpg" alt="default meal" />
  </div>
  <h1 class="mb-3 mt-3">{{mealData.translated_name}}</h1>

  <div class="d-flex justify-content-center  ">
    <a class="btn btn-square btn-primary  mx-1"><i class='bx bx-cart bx-tada'></i>


      <span class="price" *ngIf="mealData?.has_one_size">{{mealData?.price | number}} ₺</span>
      <span class="price" *ngIf="!mealData?.has_one_size">{{mealData?.sizes[0].price | number}} ₺</span></a>


  </div>
</div>

<!-- {{mealData|json}} -->


<ng-template #ItemModal let-modal class="custom_modal">
  <!-- <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
            <i class="bx bx-x"></i>
        </button>
    </div> -->
  <div class="modal-body">
    <!-- <div class="item d-flex justify-content-center align-item-center"> -->
    <div class="item">
      <div class="image">
        <button type="button" class="close_btn" aria-label="Close" (click)="modal.dismiss()">
          <i class="bx bx-x"></i>
        </button>
        <img *ngIf="mealData.photo" class="img-fluid" [src]="mainService.uploadPath +mealData.photo" alt="">
        <img class="img" *ngIf="!mealData.photo" src="assets/imgs/default-img.jpg" alt="default meal" />
      </div>
      <div class="item_data">
        <h1 class="mb-3 mt-3">{{mealData.translated_name}}</h1>
        <p>
          {{mealData.translated_description}}
        </p>


        <div class="choose_size" *ngIf="mealData.sizes && !mealData?.has_one_size">
          <div class="size_item" *ngFor="let size of mealData.sizes" (click)="onSizeChange(size)">
            <label [ngClass]="{active: mealData.selectedSize == size}">
              <span>{{size.translated_name}}</span>
              <span class="size_price">{{size.price | number}} ₺</span>
            </label>
          </div>
        </div>

        <div class="d-flex align-item-center">
          <h2 class="price" *ngIf="mealData?.has_one_size">{{mealData?.price | number}} ₺</h2>

          <div class="quantity">
            <div class="d-inline-flex align-item-center">
              <span class="dec quantity_btn" (click)="cartService.decrement(mealData)"><i
                  class='bx bx-minus'></i></span>
              <input type="text" value="1" [(ngModel)]="mealData.count" name=" quantity" disabled="true">
              <span class="inc quantity_btn" (click)="cartService.increment(mealData)"><i class='bx bx-plus'></i></span>
            </div>
          </div>
        </div>

        <div class="addons" *ngIf=" mealData?.addons?.length > 0 ">
          <h2>{{'L_Addons'|translate}}</h2>
          <div class="addon_item" *ngFor="let addon of mealData.addons ; let i = index">
            <!-- <input class="form-check-input" type="checkbox" name="addons{{i}}" id="addon{{addon.id}}" value="{{addon.id}}" (change)="onAddonsCheecked(addon,$event)"> -->
            <span class="addon_name">
              {{addon.translated_name}}
            </span>
            <span> {{addon.price | number }} ₺</span>
            <div class="quantity">
              <div class="d-inline-flex align-item-center">
                <span class="dec quantity_btn" (click)="cartService.decrementAddon(mealData ,addon)"><i
                    class='bx bx-minus'></i></span>
                <!-- {{addon.count? addon.count : 0}} -->
                <input type="text" value="1" [(ngModel)]="addon.count" name="addonNum" disabled="true">
                <span class="inc quantity_btn" (click)="cartService.incrementAddon(mealData ,addon)"><i
                    class='bx bx-plus'></i></span>
              </div>
            </div>
          </div>

        </div>

        <div class="d-flex align-item-center mt-3">
          <span class="price">{{mealData.itemTotalPrice}} ₺</span>
          <a class="btn btn-square btn_default" (click)="addMealToCart(mealData)"><i class='bx bx-cart bx-tada'></i>
            {{'L_Add_to_cart'|translate}} </a>
        </div>
      </div>
    </div>
  </div>
</ng-template>
