import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbOffcanvas, OffcanvasDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from 'src/app/services/cart.service';
import { Observable, of } from 'rxjs';
import { Order, ProductInCart, TableOrder } from 'src/app/modals/order';
import { MainService } from 'src/app/services/main.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
declare var google: any;
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],

})
export class CartComponent implements OnInit {
  userForm: FormGroup;
  userTableForm: FormGroup;
  userData: any = {};
  orderData: Order = {
    customer_name: '',
    distance: 0,
    customer_lng: '',
    customer_lat: '',
    locationOnMapAddress: '',
    customer_address: '',
    customer_mobile: '',
    building_no: '',
    apartment_no: '',
    device_key: '',
    store_id: '',
    items: []
  };
  tableOrderData: TableOrder = {
    customer_name: '',
    customer_mobile: '',
    device_key: '',
    store_id: '',
    table_slug: '',
    items: []
  };

  error_sprate = {
    lat_lng: ''
  }
  modelOpened: boolean = false;
  toogleMapLocation = false;
  storedUserData: any;
  // orderDistance = { distance : 0};

  // userDeviceKey = 'sa48764sr';
  // userDeviceKey = 'sa487r';
  storedUserDataChanged: boolean;
  deliveryPrice: any;
  // mainService.storeData: any;
  allDistance: any[];
  allRoutings: any[];
  show_success_order_message: boolean;
  show_error_validation_form: any;
  addOrder: boolean;
  totalTablePrice: any;
  estimateDeliveryPriceLoader: boolean;
  modelMapOpened: boolean;


  constructor(
    private offcanvasService: NgbOffcanvas,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private http: HttpClient,


    public cartService: CartService,
    public mainService: MainService,
    public snackBar: MatSnackBar,
    public translateService: TranslateService
  ) {
    this.userForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(4)]],
      mobile: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(11)]],
      address: ['', Validators.required],
      building_no: [''],
      apartment_no: [''],
      locationOnMapAddress: [''],
      mainData: false,
    });
    this.userTableForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(4)]],
      mobile: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(11)]],
    });

  }

  //inputs validations
  get name() {
    return this.userForm.get('name');
  }
  get mobile() {
    return this.userForm.get('mobile');
  }
  get address() {
    return this.userForm.get('address');
  }
  get nameOfTableUser() {
    return this.userTableForm.get('name');
  }
  get mobileOfTableUser() {
    return this.userTableForm.get('mobile');
  }

  promisSetUserData(userData: any) {
    return new Promise((resolve, reject) => {
      this.mainService.setUserData(this.userData).subscribe((res) => {
        resolve(res.record);
      }, err => {
        reject(false);
        if (err.status == 0) {
          this.snackBar.open(this.translateService.instant('L_errorConnection'), '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
        }
        else {
          if (err.error) {

            if (err.error.message) {
              this.snackBar.open(err.error.message, '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
            } else {
              this.snackBar.open(this.translateService.instant('L_someErrorHappen'), '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
            }
          }

        }
      });
    })
  }



  // getMyCurrentLocaiton() {
  //   this.estimateDeliveryPriceLoader = true
  //   navigator.geolocation.getCurrentPosition((position) => {
  //     this.setNewLocation(position.coords)
  //   }, error => {
  //     this.snackBar.open(this.translateService.instant('L_errorOnGetLocation'), '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
  //   });
  // }

  open(content: any) {
    // if(!this.userData.mobile){
    this.getUserData();
    // }
    this.offcanvasService.open(content, { ariaLabelledBy: 'offcanvas-basic-title' }).result.then(
      (result) => {
        // this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  ngOnInit(): void {
    this.onUserFormValueChange();
    // this.chooseTypeOfMap('determaionMyLocationAuto')
    // this.getMyCurrentLocaiton()
  }

  private getDismissReason(reason: any): string {
    if (reason === OffcanvasDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === OffcanvasDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on the backdrop';
    }
    else {
      return `with: ${reason}`;
    }
  }

  openMapLocationModal(DataModal) {
    this.modalService.open(DataModal,
      {
        fullscreen: true,
        scrollable: true,
        centered: true
      }
    );
    this.modelMapOpened = true;
  }

  openItemModal(DataModal) {
    if (this.mainService.tableId) {
      this.tableOrderData.store_id = this.mainService.storeData.id;
      this.tableOrderData.table_slug = this.mainService.tableId;
      this.tableOrderData.device_key = this.mainService.device_key;
      this.estimaTetableOrder();

    }
    console.log('tableOrderData', this.tableOrderData);
    this.modalService.open(DataModal,
      {
        scrollable: true,
        centered: true
      }
    );
    this.modelOpened = true;
  }

  //when form of user data value change;
  onUserFormValueChange() {
    this.userForm.valueChanges.subscribe(value => {
      this.storedUserDataChanged = true;
    });
  }

  getUserData() {
    console.log('this.mainService.device_key', this.mainService.device_key);
    this.mainService.getUserData(this.mainService.device_key).subscribe(
      (res) => {
        this.storedUserData = res.record;
        if (this.mainService.tableId) {
          this.userTableForm.setValue({
            name: this.storedUserData.name,
            mobile: this.mainService.englishNumbersFormat(this.storedUserData.mobile),
          });

          this.tableOrderData.customer_name = this.storedUserData.name;
          this.tableOrderData.customer_mobile = this.mainService.englishNumbersFormat(this.storedUserData.mobile);
        }
        else {
          this.userForm.setValue({
            name: this.storedUserData.name,
            mobile: this.mainService.englishNumbersFormat(this.storedUserData.mobile),
            address: this.storedUserData.address,
            locationOnMapAddress: '',
            building_no: this.storedUserData.building_no,
            apartment_no: this.storedUserData.apartment_no,
            mainData: false
          });
          this.userData.lat = this.storedUserData.lat;
          this.userData.lng = this.storedUserData.lng;
          // this.orderData.customer_lat = this.storedUserData.lat;
          // this.orderData.customer_lng = this.storedUserData.lng;
          // if (!this.orderData.distance) {
          //   this.getDistance();
          // }
        }

      }
    )
  }


  estimateDeliveryPrice() {
    this.deliveryPrice = 0
    // this.getDistance();
    this.estimateDeliveryPriceLoader = true
    this.mainService.estimateDeliveryPrice({ distance: this.orderData.distance, store_id: this.mainService.storeData.id }).subscribe(
      (res) => {
        this.deliveryPrice = res.price;
        this.estimateDeliveryPriceLoader = false
      },
      (err) => {
        this.estimateDeliveryPriceLoader = false
        this.snackBar.open(this.translateService.instant('L_DeliveryPriceError'), '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }
    )
  }


  estimaTetableOrder() {
    this.tableOrderData.items = this.getCartOrder();
    this.mainService.estimaTetableOrder(this.tableOrderData).subscribe(
      (res) => {
        this.totalTablePrice = res.record.price_with_vat;
        console.log('estimate Price', this.totalTablePrice);
      },
      (err) => {
        this.snackBar.open(this.translateService.instant('error_in_get_price'), '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }
    )
  }

  // estimateSafariOrder() {
  //   this.mainService.estimateSafariOrder(this.orderData).subscribe(
  //     (res) => {
  //       console.log('estimate order Price', this.orderData, res);
  //       console.log("======================================", res.message);
  //     },
  //     (err) => {
  //       this.snackBar.open(this.translateService.instant('L_orderPriceError'), '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
  //     }
  //   )
  // }

  async sendOrder() {
    // debugger
    this.userForm.controls['name'].markAsTouched()
    this.userForm.controls['mobile'].markAsTouched()
    this.userForm.controls['address'].markAsTouched()
    this.error_sprate.lat_lng = '';
    this.show_error_validation_form = false;
    this.orderData.items = [];
    this.userData = this.userForm.value;
    this.userData.device_key = this.mainService.device_key;
    this.userData.lat = this.orderData.customer_lat;
    this.userData.lng = this.orderData.customer_lng;
    this.userData.mobile = this.mainService.englishNumbersFormat(this.userData.mobile)
    this.orderData.device_key = this.mainService.device_key;
    this.orderData.store_id = this.mainService.storeData.id;
    this.orderData.customer_name = this.userData.name;
    this.orderData.customer_address = this.userData.address;
    this.orderData.customer_mobile = this.userData.mobile;
    this.orderData.building_no = this.userData.building_no;
    this.orderData.apartment_no = this.userData.apartment_no;


    console.log("🚀 ~ file: cart.component.ts:237 ~ CartComponent ~ sendOrder ~  orderData", this.orderData)

    if (!this.orderData.customer_lat || !this.orderData.customer_lng) {
      this.error_sprate.lat_lng = this.translateService.instant('L_pleaseEnterLocationOnMap')
      this.show_error_validation_form = true
      return
    }

    if (this.estimateDeliveryPriceLoader) {


      this.snackBar.open(this.translateService.instant('L_pleaseWaitToGetDistancePrice'), '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });

      return
    }

    if (!this.deliveryPrice || !this.orderData.distance) {
      this.snackBar.open(this.translateService.instant('L_pleaseEnureLocation'), '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });

      return
    }

    console.log("🚀 ~ file: cart.component.ts:237 ~ CartComponent ~ sendOrder ~ this.userForm.valid", this.userForm.valid)

    if (!this.userForm.valid) {
      this.show_error_validation_form = true
      return
    }



    let message = this.translateService.instant('L_newOrderSendMsg') + " %0a "
    let order_data_message = ""
    order_data_message += '- ' + this.translateService.instant('L_name') + " : " + this.userData.name + ' %0a ';
    order_data_message += '- ' + this.translateService.instant('L_Phone') + " : " + this.userData.mobile + ' %0a ';
    order_data_message += '- ' + this.translateService.instant('L_Location_map') + " : " + this.orderData.locationOnMapAddress + ' %0a ';
    order_data_message += '  https://maps.google.com/?q=' + this.orderData.customer_lat + ',' + this.orderData.customer_lng + ' %0a ';
    order_data_message += '- ' + this.translateService.instant('L_Address') + " : " + this.orderData.customer_address + ' %0a ';
    if (this.orderData.building_no) {
      order_data_message += '- ' + this.translateService.instant('L_building_number') + " : " + this.orderData.building_no + ' %0a ';
    }
    if (this.orderData.apartment_no) {
      order_data_message += '- ' + this.translateService.instant('L_Apartment_number') + " : " + this.orderData.apartment_no + ' %0a ';
    }
    order_data_message += ' %0a '
    order_data_message += this.translateService.instant('L_products') + ' %0a '
    this.cartService.shoppingCartItems.forEach((product: ProductInCart) => {
      let selected_size_name = product.has_one_size ? ' ' : ' ' + product.selectedSize.name + " "
      order_data_message += '- ' + product.name + selected_size_name + '* ' + product.count + ' %0a ';

      let product_to_send: ProductInCart = {
        id: product.id,
        count: product.count,
        addons: [],
        size: product.size,
      }
      if (!product_to_send.size) {
        delete product_to_send.size
      }
      for (let addon_index = 0; addon_index < product.addons.length; addon_index++) {
        if (product.addons[addon_index].count > 0) {

          order_data_message += '***** ' + product.addons[addon_index].name + ' * ' + product.addons[addon_index].count + ' %0a ';

          product_to_send.addons.push(product.addons[addon_index]);
        }
      }
      this.orderData.items.push(product_to_send);
    })

    order_data_message += ' %0a '


    order_data_message += this.translateService.instant('L_products_price') + " 💵 : " + this.cartService.total_pirce_all_cart + "  ₺ %0a ";
    order_data_message += this.translateService.instant('L_Delivery_price') + " 🛵 : " + this.deliveryPrice + "  ₺ %0a "
    order_data_message += this.translateService.instant('L_total_price') + " 💵 : " + (this.cartService.total_pirce_all_cart + this.deliveryPrice) + "  ₺ %0a "


    let footer_data_msg = this.translateService.instant('L_menu_link') + " %0a https://menu.akillikurye.com/" + this.mainService.storeData.slug + " %0a "


    if (!this.storedUserData || this.userForm.value.mainData) {
      this.storedUserData = await this.promisSetUserData(this.userData);
    }
    // message += this.translateService.instant('L_order_number') + " " + 55555 + ' %0a ';
    // message += order_data_message + footer_data_msg

    if (this.storedUserData) {
      this.addOrder = true;
      this.mainService.sendSafariOrder(this.orderData).subscribe(
        (res) => {
          message += this.translateService.instant('L_order_number') + " %0a " + res.record.order_store_number + ' %0a ';
          message += order_data_message + footer_data_msg
          console.log("🚀 ~ file: cart.component.ts:410 ~ CartComponent ~ sendOrder ~ message:", message)


          this.sendMessageToWhatsUp(message)
          this.addOrder = false
          this.show_success_order_message = true;
          this.cartService.removeAllCartProducts();
          this.modalService.dismissAll();
        },
        (err) => {
          this.addOrder = false
          if (err.status == 0) {
            this.snackBar.open(this.translateService.instant('L_errorConnection'), '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
          }
          else {
            if (err.error) {

              if (err.error.message) {
                this.snackBar.open(err.error.message, '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
              } else {
                this.snackBar.open(this.translateService.instant('L_someErrorHappen'), '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
              }
            }

          }
        }
      );
    }
  }

  sendMessageToWhatsUp(order_data_message) {
    if (this.mainService.storeData.phones.length > 0) {
      let index_of_mobile = this.mainService.storeData.phones.findIndex(e => e.is_whatsapp == true)
      if (index_of_mobile > -1) {
        let link = 'https://api.whatsapp.com/send?text=' + order_data_message + '&phone=+90' + this.mainService.storeData.phones[index_of_mobile].phone
        if (!window.open(link, '_blank')) {
          window.location.href = link
        }
      }
    }

  }
  dismissMenu() {
    this.offcanvasService.dismiss();
    setTimeout(() => {
      this.show_success_order_message = false

    }, 1000);

  }

  async setNewLocation(value) {

    if (this.orderData.customer_lat == value.latitude && this.orderData.customer_lng == value.longitude) {
      return
    }
    this.estimateDeliveryPriceLoader = true
    this.orderData.customer_lat = value.latitude;
    this.orderData.customer_lng = value.longitude;
    this.orderData.locationOnMapAddress = value.locationOnMapAddress;

    let locationOnMapAddress = value.locationOnMapAddress
    this.userForm.patchValue({
      locationOnMapAddress: locationOnMapAddress
    })
    this.getDistance();
  }
  // async getMapAddress(lat, lng, index): Promise<any> {
  //   let lang = this.mainService.selected_lang.lang ? this.mainService.selected_lang.lang : 'ar';
  //   let geocodeUrl = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&language=' + lang + '&key=AIzaSyAuBgi9AeZeDIUjV3fdvC8lASnpmwoxuvY';
  //   return await new Promise(resolve => {
  //     this.http.get(geocodeUrl).subscribe((data: any) => {
  //       if (data) {
  //         // code...
  //         if (data.results[index]) {
  //           // code...
  //           resolve(data.results[index].formatted_address)
  //         }
  //       }
  //     });
  //   });


  // }


  getDistance() {
    this.estimateDeliveryPriceLoader = true

    this.allDistance = []

    this.allRoutings = []
    this.orderData.distance = 0;
    console.log(`cutomer lat ${this.orderData.customer_lat} customer lng ${this.orderData.customer_lng} , store lat ${this.mainService.storeData.lat}, store lng ${this.mainService.storeData.lng}`);
    if (this.orderData.customer_lat && this.orderData.customer_lng) {
      let directionsService = new google.maps.DirectionsService();
      var request: any = {
        // origin: `${position.coords.latitude},${position.coords.longitude}`,
        origin: `${this.mainService.storeData.lat},${this.mainService.storeData.lng}`,
        destination: `${this.orderData.customer_lat},${this.orderData.customer_lng}`,

        travelMode: 'DRIVING',
        provideRouteAlternatives: true,
      };
      let me = this;
      directionsService.route(request, function (result, status) {
        if (status == google.maps.DirectionsStatus.OK) {
          // Loop through the routes to find the shortest one
          for (var i = 0; i < result['routes'].length; i++) {
            //allRoutings
            me.allRoutings.push({ "duration": result['routes'][i].legs[0].duration, "distance": result['routes'][i].legs[0].distance, "start_address": result['routes'][i].legs[0].end_address, "end_address": result['routes'][i].legs[0].end_address, })

            //allDistance
            me.allDistance.push(result['routes'][i].legs[0].distance.value / 1000)
          }
          me.resetDistance();
        }
      });
    }
  }

  resetDistance() {
    if (this.allDistance.length > 0) {
      this.allDistance.sort(function (a, b) {
        return a - b;
      });
      if (this.allDistance[0]) {
        this.orderData.distance = this.allDistance[0];
        if (this.orderData.distance > 0) {
          this.estimateDeliveryPrice();
        }
      }
    }
  }

  async sendTetableOrder() {
    this.userData = this.userTableForm.value;
    this.tableOrderData.customer_name = this.userData.name;
    this.tableOrderData.customer_mobile = this.userData.mobile;
    this.tableOrderData.customer_mobile = this.mainService.englishNumbersFormat(this.tableOrderData.customer_mobile),

      this.tableOrderData.items = this.getCartOrder();
    console.log('sendTetableOrder', this.tableOrderData);
    if (!this.userTableForm.valid) {
      this.show_error_validation_form = true
      return
    }

    this.addOrder = true;
    this.mainService.sendTetableOrder(this.tableOrderData).subscribe(
      (res) => {
        this.addOrder = false;
        this.show_success_order_message = true;
        this.cartService.removeAllCartProducts();
        this.modalService.dismissAll();
      },
      (err) => {
        this.addOrder = false
        if (err.status == 0) {
          this.snackBar.open(this.translateService.instant('L_errorConnection'), '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
        }
        else {
          if (err.error) {

            if (err.error.message) {
              this.snackBar.open(err.error.message, '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
            } else {
              this.snackBar.open(this.translateService.instant('L_someErrorHappen'), '', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
            }
          }

        }
      }
    );
  }

  getCartOrder() {
    this.tableOrderData.items = [];
    // let order_data_message = ""
    // order_data_message += '- ' + this.translateService.instant('L_name') + " " + this.userData.name + ' %0a ';
    // order_data_message += '- ' + this.translateService.instant('L_Phone') + " " + this.userData.mobile + ' %0a ';

    this.cartService.shoppingCartItems.forEach((product: ProductInCart) => {
      // let selected_size_name = product.has_one_size ? ' ' : ' ' + product.selectedSize.name + " "
      // order_data_message += '- ' + product.name + selected_size_name + '* ' + product.count + ' %0a ';
      let product_to_send: ProductInCart = {
        id: product.id,
        count: product.count,
        addons: [],
        size: product.size,
      }
      if (!product_to_send.size) {
        delete product_to_send.size
      }
      for (let addon_index = 0; addon_index < product.addons.length; addon_index++) {
        if (product.addons[addon_index].count > 0) {
          // order_data_message += '***** ' + product.addons[addon_index].name + ' * ' + product.addons[addon_index].count + ' %0a ';
          product_to_send.addons.push(product.addons[addon_index]);
        }
      }
      this.tableOrderData.items.push(product_to_send);
    })
    return this.tableOrderData.items;
  }


}


