<button class="btn btn-lg my_orders" (click)="openOrdersList(content)">
  <i class='bx bx-list-check'></i> {{'L_Orders'|translate}}
</button>

<ng-template #content let-modal>
  <div class="container">
    <div class="modal-header">
      <!-- <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
        <i class="bx bx-home"></i>
      </button> -->
      <h4 class="modal-title">{{'L_My_Orders'|translate}}</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
        <i class="bx bx-home"></i>
      </button>
      <!-- </div> -->
    </div>
    <div class="modal-body scrolled_content">
      <!-- <div class="container"> -->
      <!-- {{previousOrders|json}} -->
      <div id="overlay" class="loading_data" *ngIf="pageLoader">
        <div class="spinner"></div>
        <br />
        <h2>
          {{'L_Loading_data'|translate}}
        </h2>
      </div>


      <ng-container *ngIf="!pageLoader">
        <div class="order_item rounded" *ngFor="let order of  this.ordersData.data   ; let i = index">
          <div class="main_data">
            <div class="top_data d-flex align-item-center justify-content-between" (click)="getSingleOrderData(i)">
              <div>
                <p class="mb-0 store_name"><i class='bx bxs-store-alt'></i> {{order.store.name}}</p>
                <h2 class="mb-0">{{'L_order_number'|translate}}: <span dir="ltr"> {{order.order_store_number}}</span>
                </h2>
              </div>
              <div>
                <p class="status text-white py-2 px-3 rounded status_{{order.status.id}}">
                  {{order.status.name}}</p>
                <p class="date font-weight-bold text-center"><i class='bx bx-calendar'></i> {{order.created_at| date
                  :'short'}}</p>
              </div>
            </div>
            <button class="btn btn-square btn_default alarm_btn"
              *ngIf="order.order_type == 'tables' && order.status.id != 7" (click)="sendAlarm(order.id)"><i
                class='bx bx-bell'></i> {{'L_send_alarm'|translate}}</button>
            <ng-container *ngIf="order.detailsOpend">
              <div class="load_item" *ngIf="order.loader">
                <div class="spinner"></div>
              </div>
              <div class="order_details" id="order{{order.id}}" *ngIf="!order.loader">
                <div class="bottom_data d-flex pt-3 align-item-center justify-content-between">
                  <div class="meals_data">
                    <div class="meal_item" *ngFor="let meal of order.orderData.details ; let i = index ">
                      <div class="m_name">
                        <span class="name"> - {{meal.details.name[selectedLang]}} </span>
                        <span class="size" *ngIf=" meal.details.size">(
                          {{meal.details.size.translated_name}}
                          )</span>* {{meal.count}} / {{meal.price |number}} ₺
                        <!-- <span class="count"> * {{meal.details.count}} )</span>  -->
                      </div>
                      <div class="addons_list">
                        <span class="addons" *ngFor="let addon of meal.details.addons">
                          <span class="name">{{addon.name[selectedLang]}}</span>
                          <span class="addon_count"> * {{addon.count}} / {{meal.price |number}} ₺</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="m-0 total">
                    <h4>{{'L_total_price'|translate}}</h4>
                    <h2>{{order.total_with_vat |number }} ₺</h2>
                  </div>
                </div>
                <div>
                  <h4>{{'L_resturant_data'| translate}}</h4>
                  <!-- {{order.orderData|json}} -->
                  <ul class="d_list">
                    <li><span>{{'L_storeName'| translate}}:</span> <span>{{order.orderData.store.name}}</span>
                    </li>

                    <li>
                      <span>{{'L_Phone'| translate}}:</span>
                      <div class="phones">
                        <ng-container *ngFor="let phoneData of order.orderData.store.phones">
                          <a href="tel:{{phoneData.phone}}"><i class='bx bxs-phone'></i></a>
                          <a *ngIf="phoneData.is_whatsapp"
                            href="https://api.whatsapp.com/send?phone=90{{phoneData.phone}}" target="_blank"><i
                              class='bx bxl-whatsapp'></i></a>
                        </ng-container>
                      </div>

                    </li>
                    <li><span>{{'L_Address'| translate}}:</span> <span>{{order.orderData.store.address}}</span>
                    </li>
                  </ul>
                  <h4>{{'L_customer_data'| translate}}</h4>
                  <ul class="d_list">
                    <li><span>{{'L_name'| translate}}:</span>
                      <span>{{order.orderData.customer_name}}</span>
                    </li>
                    <li><span>{{'L_Phone'| translate}}:</span>
                      <span>{{order.orderData.customer_mobile}}</span>
                    </li>
                    <li><span>{{'L_Address'| translate}}:</span>
                      <span>{{order.orderData.customer_address}}</span>
                    </li>
                    <li><span>{{'L_building_number'| translate}}:</span>
                      <span>{{order.orderData.building_no}}</span>
                    </li>
                    <li><span>{{'L_Apartment_number'| translate}}:</span>
                      <span>{{order.orderData.apartment_no}}</span>
                    </li>
                  </ul>
                  <!-- <h4>{{'order_data'| translate}}</h4> -->
                  <!-- <ul class="d_list">
              <li *ngFor="let meal of order.orderData.details">
                <h2>{{meal.translated_name}} - {{meal.count}}</h2>
              </li>
            </ul> -->
                </div>
              </div>
            </ng-container>
          </div>
        </div>

      </ng-container>

      <div class="order_empty" *ngIf="ordersData?.data?.length <=  0 && !pageLoader">
        <div class="inner text-center">
          <img class="mb-3" src="assets/imgs/cloud.png" alt="cart empty">
          <h2>{{'L_no_orders'|translate}}</h2>
          <button class="btn btn-square btn_default continue_shop mt-4" (click)="modal.dismiss()">
            {{'L_back_home'|translate}} </button>
        </div>
      </div>


      <ngb-pagination *ngIf="ordersData.total > ordersData.per_page" class="d-flex justify-content-center"
        (pageChange)="pageChange($event)" [collectionSize]="ordersData.total" [maxSize]="5"
        [pageSize]="ordersData.per_page" [(page)]="ordersData.current_page"></ngb-pagination>
    </div>
  </div>
</ng-template>
