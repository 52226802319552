import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import getBrowserFingerprint from 'get-browser-fingerprint';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  
  public apiRoot: string = environment.domin + 'api/';
  public uploadPath: string = environment.domin + 'storage/';
  public defualt_lang = { label: 'عربي', lang: 'ar' };
  public selected_lang = { label: '', lang: '' };

  public device_key: any;


  socialSettings: any = {};
  // appSettings: any = {};

  storeSlug: any;
  urlArr: any;
  tableId: any;


  public Languages = [
    {
      label: 'عربي',
      lang: 'ar'
    },
    {
      label: 'English',
      lang: 'en'
    },
    {
      label: 'Türkçe',
      lang: 'tr'
    },
  ];

  storeData: any = {};

  public primaryColor = '#007bff'; 
  constructor(private http: HttpClient, public translate: TranslateService) {
    this.device_key = "M_"+ getBrowserFingerprint();//k-2675875017
    console.log("🚀 ~ file: main.service.ts:12 ~ MainService ~ environment:", environment)

  
    console.log("🚀 ~ file: main.service.ts:42 ~ MainService ~ constructor ~  this.device_key",  this.device_key);
  }



  chooseLanguage(language: any, reload) {
    if (!language.lang) {
      language = this.defualt_lang;
    }
    this.translate.use(language.lang);
    this.localStorage().setObject('selected_lang', language);
    document.getElementsByTagName('html')[0].setAttribute('dir', language.lang === 'ar' ? 'rtl' : 'ltr');
    document.getElementsByTagName('html')[0].setAttribute('lang', language.lang);
    if (reload && this.selected_lang.lang != language.lang) {
      location.reload();
    }
    this.selected_lang = language;
  }

  localStorage() {
    return {
      set: function (key, value) {
        window.localStorage[key] = value;
      },
      get: function (key) {
        return window.localStorage[key] || '';
      },
      setObject: function (key, value) {
        window.localStorage[key] = JSON.stringify(value);
      },
      getObject: function (key) {
        return JSON.parse(window.localStorage[key] || '{}');
      }
    };
  }

  getSomeData() {
    this.getSocialForMenu().subscribe((res) => {
      this.socialSettings = res.records
    })
    // this.getSettingsForApp().subscribe((res) => {
    //   this.appSettings = res.records
    // })
  }
  getUserData(devicekey: any) {
    return this.http.get<any>(
      this.apiRoot +
      "customer/get/my-data/" +
      devicekey
    );
  }

  setUserData(userData: any) {
    return this.http.post<any>(
      this.apiRoot +
      "customer/save/my-data",
      userData
    );
  }

  estimateDeliveryPrice(data: any) {
    return this.http.post<any>(
      this.apiRoot +
      "customer/estimate/delivery-price",
      data
    );
  }

  estimateSafariOrder(Order) {
    return this.http.post<any>(
      this.apiRoot +
      "customer/estimate/safari-order",
      Order
    );
  }
  sendSafariOrder(Order) {
    return this.http.post<any>(
      this.apiRoot +
      "customer/make/safari-order",
      Order
    );
  }

  getSocialForMenu() {
    return this.http.get<any>(
      this.apiRoot +
      "settings/social",
    );
  }

  getPreviousOrders(userId, params) {
    return this.http.get<any>(
      this.apiRoot +
      "customer/get/my-orders/" +
      userId, { params: params }
    );
  }
  getSingleOrderData(userId, orderId) {
    return this.http.get<any>(
      this.apiRoot +
      "customer/get/order-info/" +
      orderId + '/' +
      userId
    );
  }
  // getSettingsForApp() {
  //   return this.http.get<any>(
  //     this.apiRoot +
  //     "settings/apps",
  //   );
  // }


  estimaTetableOrder(Order) {
    return this.http.post<any>(
      this.apiRoot +
      "customer/estimate/table-order",
      Order
    );
  }

  sendTetableOrder(Order) {
    return this.http.post<any>(
      this.apiRoot +
      "customer/make/table-order",
      Order
    );
  }

  //still not used
  doAlarm(orderId:any) {
    return this.http.post<any>(
      this.apiRoot +
      "customer/do-alarm/"+
      orderId +
      "/" +
      this.device_key
      ,
      null
    );
  }


  englishNumbersFormat(str) {
    if (str) {
      str = '' + str
      let
        persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
        arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
      for (var i = 0; i < 10; i++) {
        str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
      }
      return str;
    } else {
      return '';
    }
  }


  getSlugFromUrl(urlArr:any){
    this.storeSlug = urlArr[urlArr.length - 1];
    this.tableId = '';
    if(this.storeSlug.lastIndexOf("table=") != -1){
      this.tableId = this.storeSlug.slice(this.storeSlug.lastIndexOf("table=") + 6,this.storeSlug.length);
    }
    console.log('slug ======================================= from service', this.storeSlug , this.tableId);
  }


}
