<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="footer_about">
          <div class="footer_logo">
            <img src="assets/imgs/logo.png" alt="">
          </div>
          <div class="footer_social">
            <a href="{{this.mainServive.socialSettings?.facebook?.value}}"
              *ngIf="this.mainServive.socialSettings?.facebook?.value" target="_blank">
              <i class="bx bxl-facebook-square"></i>
            </a>
            <a href="{{this.mainServive.socialSettings?.twitter?.value}}"
              *ngIf="this.mainServive.socialSettings?.twitter?.value" target="_blank">
              <i class="bx bxl-twitter"></i>
            </a>
            <a href="{{this.mainServive.socialSettings?.instagram?.value}}"
              *ngIf="this.mainServive.socialSettings?.instagram?.value" target="_blank">
              <i class="bx bxl-instagram"></i>
            </a>
            <a href="https://api.whatsapp.com/send?phone=90{{this.mainServive.socialSettings?.whats_app?.value}}"
              *ngIf="this.mainServive.socialSettings?.whats_app?.value" target="_blank"><i class="bx bxl-whatsapp">

              </i>
            </a>
            <a href="{{this.mainServive.socialSettings?.youtube?.value}}"
              *ngIf="this.mainServive.socialSettings?.youtube?.value" target="_blank">
              <i class='bx bxl-youtube'></i>
            </a>
            <a *ngIf="this.mainServive.socialSettings?.email?.value"
              href="mailto:{{this.mainServive.socialSettings?.email?.value}}" target="_blank"><i
                class='bx bx-envelope'></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright">
    <div class="container">
      <p class="copyright_text text-white text-center">
        akillikurye.com &copy; 2023
      </p>
    </div>
  </div>
</footer>
