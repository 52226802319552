<div id="overlay" class="loading_data" *ngIf="loading">
  <div class="spinner"></div>
  <br />
  <!-- <h2>
        {{'L_Loading_data'|translate}}
    </h2> -->
</div>
<div *ngIf="!loading"  
>


  <div class="top_action_button d-flex align-item-center justify-content-between" id="navbar">
    <div>

   

      <app-change-language></app-change-language>
      <app-cart></app-cart>
    </div>
    <app-my-orders style="align-self: flex-end;"></app-my-orders>
  </div>
  <!-- <app-scroll-to-top></app-scroll-to-top> -->
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</div> 
<!-- --secondary_color -->