import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  constructor(private http: HttpClient, private mainService: MainService) {
  }

  getAllMenuData(slug: any) {
    return this.http.get<any>(
      this.mainService.apiRoot +
      'menu/of/' +
      slug,
    );
  }
  getResturantData(slug: any) {
    return this.http.get<any>(
      this.mainService.apiRoot +
      "customer/get/store-info/" +
      slug
    );
  }

}
