<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
  <i class='bx bx-globe'></i>
  {{this.mainService.selected_lang.label}}
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item *ngFor="let item of this.mainService.Languages"
    (click)="this.mainService.chooseLanguage(item , true)">
    <span>{{item.label}}</span>
  </button>
</mat-menu>
