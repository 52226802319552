import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from 'src/app/services/cart.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-meal-item',
  templateUrl: './meal-item.component.html',
  styleUrls: ['./meal-item.component.scss']
})
export class MealItemComponent implements OnInit {


  limit = 50;
  disabled = false;
  @Input() mealData: any;



  itemAddonsPrice = 0;

  mealAddonsAdded: any[] = [];

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public cartService: CartService,
    public mainService: MainService

  ) { }

  ngOnInit(): void {
    if(!this.mealData.has_one_size){
      this.mealData.selectedSize = this.mealData.sizes[0]
    }
  }
  openItemModal(ItemModal?: any) {
    this.mealData.addons.forEach((element: any) => {
      element.count = 0;
    });
    this.mealData.count = 0
    this.cartService.increment(this.mealData)
    this.modalService.open(ItemModal,
      {
        scrollable: true,
        centered: true
      }
    );
  }
  onSizeChange(mSize: any) {
    this.mealData.selectedSize = mSize;
    this.cartService.calTotalItemPrice(this.mealData);

  }
  addMealToCart(mealItem: any) {
    // mealItem.itemTotalPrice = Math.round(mealItem.itemTotalPrice * 1000) / 1000;
    this.cartService.addToCart(mealItem);
    this.modalService.dismissAll();
  }


}
